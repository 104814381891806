import React from "react";

const PersonalInfo = () => {


    const personalInfoContent = [
        {
            id: 1,
            colClass: "left",
            info: [
                {
                    id: 1,
                    name: "Age",
                    content: new Date().getFullYear() - 1974,
                },
                {
                    id: 3,
                    name: "Living",
                    content: "Edam, The Netherlands",
                },
                {
                    id: 4,
                    name: "Email",
                    content: (
                        <>
                            <a href="mailto:q@qman.io">q@qman.io</a>
                        </>
                    ),
                },

            ],
        },
        {
            id: 2,
            colClass: "right",
            info: [
                {
                    id: 1,
                    name: "Nationality",
                    content: "Dutch",
                },
                {
                    id: 2,
                    name: "Study",
                    content: "Maastricht University",
                },
                {
                    id: 3,
                    name: "Degree",
                    content: "Master",
                },

            ],
        },
    ];
    return (
        <>
            {personalInfoContent.map((item) => (
                <div className={item.colClass} key={item.id}>
                    <div className="tokyo_tm_info">
                        <ul>
                            {item?.info?.map((value) => (
                                <li key={value.id}>
                                    <span>{value.name}:</span>
                                    <span>{value.content}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </>
    );
};

export default PersonalInfo;
