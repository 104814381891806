import React from "react";
import Social from "./Social";

const Home = () => {
    return (<>
        <div className="tokyo_tm_home">
            <div className="home_content">
                <div className="avatar">
                    <div
                        className="image avatar_img"
                        style={{
                            backgroundImage: "url(assets/img/slider/1.png)",
                        }}
                    ></div>
                </div>
                <div className="details">
                    <h3 className="name">Welcome</h3>
                    <p className="job">
                        Hi, I'm Pieter Leek.
                        I'm an experienced lecturer, software developer, IT trainer, and educator. I'm passionate about teaching and creating innovative software solutions. I enjoy tackling complex challenges and take pride in developing and improving IT solutions.
                    </p>
                    <Social />
                </div>
            </div>
        </div>
    </>);
};

export default Home;
