import React from "react";

const KnowledgeInterest = () => {
    const knowledgeContent = [
        {
            id: 1,
            colClass: "left",
            title: "Knowledge",
            content: [
                "Java, JavaScript, TypeScript, Python, PHP",
                "React, Vue, Angular, TailwindCSS, Bootstrap",
                "Spring Boot, Hibernate, JPA, JUnit, Mockito, Maven, Gradle",
                "Node.js, Express.js",
                "MongoDB, MySQL, PostgreSQL, Redis, Elasticsearch",
                "AWS, GCP",
                "Web3, Ethereum, Smart Contracts, Solidity, Cardano",
                "Truffle, Ganache, Web3.js, Hardhat, OpenZeppelin",
                "Teaching, Training, Programmatic assessment",
                "Curriculum development, Assessment development",
                "Problem Solving, Troubleshooting",
            ],
        },
        {
            id: 2,
            colClass: "right",
            title: "Personal interests",
            content: [
                "Travel, Adventure, Camping, Train travel",
                "Land Rover Defenders, Sailing",
                "Playing golf, Chess, DIY, Learning new things",
                "Coking, Begium beers, Wine, Whisky, Coffee",
                "NLP, Human Design (I'm a 5/2 reflector)",
                "Economics, Politics, History, Philosophy"
            ],
        },
    ];

    return (
        <>
            {knowledgeContent.map((item) => (
                <div className={item.colClass} key={item.id}>
                    <div className="tokyo_section_title">
                        <h3>{item.title}</h3>
                    </div>
                    <div className="tokyo_tm_skill_list">
                        <ul>
                            {item.content.map((val, i) => (
                                <li key={i}>
                  <span>
                    <img
                        className="svg"
                        src="/assets/img/svg/rightarrow.svg"
                        alt="arrow"
                    />
                      {val}
                  </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </>
    );
};

export default KnowledgeInterest;
