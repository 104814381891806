import React from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

const Defender = () => {
    return (
        <>
            <div className="container">
                <div className="tokyo_tm_portfolio">
                    <div className="tokyo_tm_title">
                        <div className="title_flex">
                            <div className="left">
                                <h3>Land Rover Defender 110</h3>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio_filter">
                        <Tabs>
                            <TabList>
                                <Tab>Building </Tab>
                                <Tab>Adventures</Tab>
                            </TabList>
                            <div className="list_wrapper">
                                <TabPanel>
                                    <p>In early 2023, I bought a Land Rover Defender, a long-cherished dream of mine.
                                        This iconic car has always fascinated me. It's a black 110 model from 2008,
                                        equipped with various accessories by the previous owner, giving it a rugged look
                                        right from the purchase. Owning a Defender is truly an experience. This car
                                        doesn't drive well on the road at all; you can't even have a decent conversation
                                        above 80 kilometers per hour, and there's always something broken or simply not
                                        working. Sometimes, when it rains, water even leaks inside. It consumes way too
                                        much diesel, and the tax is enormous. So, all in all, it's certainly nice to have
                                        one. :) </p><br/>

                                    <img src="./assets/img/defender0.png" alt="My defender 110"/><br/>
                                    <br/>
                                    <p>My project was to convert this Defender into a 4x4 camper with a safari-like
                                        style. The first step was to remove the rear seats and chairs from the car,
                                        which turned out to be quite a challenge as they were quite firmly stuck.</p>
                                    <p>I installed a 36-liter water tank from Frontrunner behind the left rear wheel.
                                        During the process, I discovered that there was corrosion present, causing all
                                        the bolts to be rusted tight. I had to use a grinder to remove them and many other parts such as the exhaust also had to be removed.
                                        Installing the water tank took more time and effort than planned, but the end result is
                                        worth it.</p>
                                    <br/>
                                    <img src="./assets/img/water1.png" alt="Watertank"/><br/><br/>
                                    <img src="./assets/img/water2.png" alt="Watertank"/><br/><br/>
                                    <img src="./assets/img/water3.png" alt="Watertank"/>
                                    <br/><br/>
                                    <p>On the side, I mounted brackets and a plate for the sand recovery boards from
                                        Offroad-Tec.
                                        This plate can also serve as a table and is beautifully finished. I use MAXTRAX
                                        recovery boards for sandboards (and many more handy thinks), which I have attached to the offroad-tec
                                        plate using bolts.</p><br/>

                                    <p>Since I have a Defender 110, I utilize the two side doors. Behind the driver's
                                        side door, there is a pull-out kitchen. Using two sturdy guides, the entire
                                        kitchen can be pulled outwards. On top of the kitchen, there is an oak
                                        countertop and a Coleman unleaded two-burner 424 gasoline stove. On the rear
                                        door, on the passenger side, I have installed a pull-out Dometic CFX3 fridge
                                        with a capacity of 25 liters.</p><br/>
                                    <img src="./assets/img/keuken1.png" alt="Kitchen"/>
                                    <br/><br/>
                                    <img src="./assets/img/keuken2.png" alt="keuken"/>
                                    <br/><br/>


                                    <p>On the roof, I have mounted an Eezi Awn Series 3 - 1400 rooftop tent, and on the side,
                                        there is an Eezi Awn awning. At the front, there are two Frontrunner Wolfpack
                                        Pro boxes, two steel jerry cans, a large aluminum storage box, and on the side,
                                        there is an axe.</p><br/>

                                    <img src="./assets/img/daktent.png" alt="Rooftent"/><br/>
                                    <br/>

                                    <p> Inside, I have created a seating bench and a work surface. One of the design
                                        requirements was to be able to sleep inside if we couldn't or didn't want to use
                                        the rooftop tent. This meant that the seating bench had to be low, which in turn
                                        affected the height of the kitchen and fridge. One thing I've learned is that
                                        everything is interconnected. If you make changes in one area, it can have
                                        consequences for other aspects of the setup.</p><br/>

                                    <br/>
                                </TabPanel>
                                <TabPanel>

                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Defender;
