import React, {useEffect} from "react";
import AllRoutes from "./router/AllRoutes";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import {ToastContainer} from "react-toastify";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCMtsGJoH7qNqFDnKmyqSkCCIDiBAuL5CE",
    authDomain: "qman-website.firebaseapp.com",
    projectId: "qman-website",
    storageBucket: "qman-website.appspot.com",
    messagingSenderId: "393742256542",
    appId: "1:393742256542:web:d46720d67c7a57f99c6e8f",
    measurementId: "G-W0YEC85P2M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);


const App = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="tokyo_tm_all_wrap">
            <AllRoutes/>
            <ToastContainer/>
        </div>
    );
};

export default App;
