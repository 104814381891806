import React from "react";
import Website from "../views/Website";
import NotFound from "../views/NotFound";
import {Route, Routes} from "react-router-dom";

const AllRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Website/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </>
    );
};

export default AllRoutes;
