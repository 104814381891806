import React from "react";

const Intro = () => {
    const introContent = {
        image: "assets/img/slider/PieterLeek.png",
        name: "Pieter Leek",
        designation: "Backend software developer, Technical trainer, Teacher",
        text: (
            <>
                <p>
                    With over 25 years of experience in IT and more than 12 years in teaching, mentoring, and coaching, I have a passion for inspiring my students to learn and grow both in IT and as individuals. I enjoy connecting with students from different backgrounds and helping them reach their full potential.</p>
                <p>
                    As a software developer, I’ve worked on many projects, from small applications to large enterprise systems. My solid understanding of software development, including object-oriented programming, data structures, algorithms, and testing, helps me deliver high-quality solutions. My love for IT began when I first connected to the internet in 1991, even before the World Wide Web became popular. I held my first website-building training in 1993, although it wasn’t well-received at the time since the internet was still new and unfamiliar to many.
                </p>
                <p>
                    My curiosity for technology has led me to become skilled in various areas of ICT, such as web development, network management, and security. I continuously update my knowledge to stay current with the latest trends in software development. I am proficient in several programming languages, including Java, Python, JavaScript, and TypeScript.
                </p>
                <p>
                    One of my greatest skills is my ability to clarify and simplify complex situations. I strive to help others understand information more easily, empowering them to make better decisions.
                </p>
                <p>
                    Friends, family, and colleagues see me as a versatile person with a wide range of skills and interests. Outside of teaching and software development, I love reading, traveling (having journeyed across the globe to over 60 countries), DIY projects, and camping in my Land Rover Defender. I also cherish spending time with my family and friends. If you have any questions or would like to learn more about my experience, please feel free to reach out. I look forward to connecting with you!
                </p>


            </>
        ),
    };

    return (
        <>
            <div className="top_author_image">
                <img src={introContent.image} alt="about" />
            </div>
            <div className="about_title">
                <h3>{introContent.name}</h3>
                <span>{introContent.designation}</span>
            </div>
            <div className="about_text">{introContent.text}</div>
        </>
    );
};

export default Intro;
