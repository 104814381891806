import React from "react";
import {Helmet} from "react-helmet-async";

const PageTitle = ({title}) => {
    return (
        <Helmet>
            <title>Website Pieter Leek - Technical Trainer - ICT lecture - Creative Software Developer </title>
            <meta name="description" content="Pieter Leek's website, ICT teacher, software developer in Java, technical trainer and Land Rover Defender enthusiast"/>
            <meta name="keywords" content="Pieter Leek, ICT teacher, software developer, technical trainer, Land Rover Defender, Edam, Amsterdam, Netherlands"/>
            <meta name="author" content="Pieter Leek"/>
        </Helmet>
    );
};

export default PageTitle;
